<template>
  <div>
    <Breadcrumb></Breadcrumb>
    <div style="margin-bottom: 20px">
      <!-- <el-button
        :disabled="!$isPowers('teamCreate')"
        size="small"
        type="primary"
        v-if="userType === 2"
        @click="$handleRoute('TeamCreate')"
        >新建团队</el-button
      >
      <el-button
        :disabled="!$isPowers('teamCopy')"
        type="primary"
        size="small"
        v-if="userType === 2 || userType === 1"
        @click.stop="$handleRoute({ cid: teamInfo.id }, 'TeamCreate')"
        >拷贝团队</el-button
      > -->
      <!-- <el-button
        size="small"
        type="primary"
        @click="$handleRoute('TeamHome')"
        v-if="teamInfo.isManager || teamInfo.is_member"
        >进入团队主页面</el-button
      >
      <el-button
        size="small"
        type="primary"
        :disabled="!$isPowers('teamAdd')"
        @click="
          $handleConfirm(
            '确定要申请加入此团队?',
            '/api/v1_0_0.user_group/join',
            { group_id: team_id },
            { title: '加入', successMessage: '加入申请已提交，待审核！' }
          )
        "
        v-else-if="userInfo.id"
        >申请加入</el-button
      > -->

      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="info">
          <el-row v-loading="loading">
            <el-col :span="14">
              <el-card
                style="margin-right: 20px"
                :body-style="{ height: '160px' }"
              >
                <div
                  style="display: flex; line-height: 40px; margin:10px 0px 20px"
                >
                  <el-avatar :src="teamInfo.logo"></el-avatar>
                  <div
                    style="margin-left: 5px; font-weight: 700; flex: 1"
                    class="shenluehao"
                  >
                    {{ teamInfo.name }}
                  </div>
                </div>
                <div style="line-height: 40px">
                  <div>
                    <div style="display: flex">
                      <div style="width: 60%">
                        <span style="font-weight: bold;color: #909399;font-size: 14px;">团队名称：
                          </span>
                        <span style="color: #606266;font-size: 14px;">{{ teamInfo.name }}</span>
                      </div>
                      <div
                        style="
                          flex: 1;
                          display: flex;
                          justify-content: space-between;
                        "
                      >
                        <div>
                          <span style="font-weight: bold;color: #909399;font-size: 14px;">地域：</span
                          >
                          <span style="color: #606266;font-size: 14px;">{{ teamInfo.province_str || "" }}</span>
                        </div>
                        <div>
                          <span style="font-weight: bold;color: #909399;font-size: 14px;">类别：</span
                          >
                          <span style="color: #606266;font-size: 14px;">{{ teamInfo.type_str || "" }}</span>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex">
                      <div style="width: 60%">
                        <span style="font-weight: bold;color: #909399;font-size: 14px;">创建时间：</span
                        >
                        <span style="color: #606266;font-size: 14px;">{{ teamInfo.create_time_f }}</span>
                      </div>
                      <div>
                        <span style="font-weight: bold;color: #909399;font-size: 14px;">创建人：</span
                        >
                        <span style="color: #606266;font-size: 14px;">{{ teamInfo.create_name || "" }}</span>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </el-card>
            </el-col>
            <el-col :span="10">
              <el-card :body-style="{  height: '160px' }">
                <div style="display: flex; justify-content: space-between">
                  <div style="flex: 1;margin-left: 30PX;">
                      <span style="font-weight: bold">团队介绍：</span
                      ><span v-html="teamInfo.description"></span>
                  </div>
                  <div>
                    <div
                      style="margin-left: 5px; font-weight: bold"
                      class="shenluehao"
                    >
                      团队LOGO
                    </div>
                    <el-image
                      v-if="teamInfo.logo"
                      :fit="'contain'"
                      style="width: 140px; height: 140px"
                      :src="teamInfo.logo"
                      alt=""
                    ></el-image>
                  </div>
               
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-card style="margin-top: 20px" v-loading="tableLoading">
            <el-table
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="dark"
              :row-key="(row) => row.id"
              :row-style="{ height: '0' }"
              :cell-style="{ padding: '6px' }"
              @sort-change="courseSortChange" 

              :header-cell-style="{
                height: '50',
                background: '#F9F9F9',
              }"
            >
              <el-table-column type="index" width="50" label="序号" align="center">
              </el-table-column>
              <el-table-column :label="teamInfo.type==99?'成员姓名':'学生姓名'" prop="realname" sortable="realname" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.realname }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学校" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.school_name }}</span>
                </template>
              </el-table-column>
            </el-table>
            <div class="pagination" style="margin-top: 20px">
              <el-pagination
                background
                layout="total, sizes, prev, pager, next"
                :total="total"
                @current-change="currentChange"
                @size-change="handleSizeChange"
                :page-size="pageSize"
                :current-page="page"
              >
              </el-pagination>
            </div>
          </el-card>
        </el-tab-pane>
        <el-tab-pane label="学员" name="stu" v-if="teamInfo.is_member && teamInfo.type!=99">
          <stu v-if="activeName == 'stu'" :group_id="Number(team_id)"></stu>
        </el-tab-pane>
        <el-tab-pane label="出勤" name="attendance" v-if="teamUser.is_master && teamInfo.course_id>0 ">
          <attendanceList v-if="activeName == 'attendance'" :group_id="Number(team_id)"></attendanceList>
        </el-tab-pane>
        <el-tab-pane label="作业" name="work" v-if="teamInfo.is_member && teamInfo.course_id>0">
          <workList v-if="activeName == 'work'" :course_id="teamInfo.course_id"></workList>
        </el-tab-pane>
        <el-tab-pane label="学情(本班课)" name="now" v-if="teamInfo.is_member && teamInfo.course_id>0"> 
          <totalDetail :course_id="teamInfo.course_id || course_id" v-if="activeName == 'now'"></totalDetail>
        </el-tab-pane>
        <el-tab-pane label="学情(历史)" name="history" v-if="teamInfo.is_member && teamInfo.course_id>0">
          <stuSituation v-if="activeName == 'history'" :course_id="teamInfo.course_id"></stuSituation>
        </el-tab-pane>
        <el-tab-pane label="题单" name="questionList" v-if="teamInfo.is_member&& teamInfo.type!=99">
          <QuestList :team_id="team_id" v-if="activeName == 'questionList'"></QuestList>
        </el-tab-pane>
        <el-tab-pane label="题目" name="topic" v-if="teamInfo.is_member&& teamInfo.type!=99">
          <QuestionList :team_id="team_id" v-if="activeName == 'topic'"></QuestionList>
        </el-tab-pane>
        <el-tab-pane label="通知" name="notice" v-if="teamInfo.is_member&& teamInfo.type!=99">
          <NoticeList :team_id="team_id" :teamUser="teamUser" v-if="activeName == 'notice'"></NoticeList>
        </el-tab-pane>
        <el-tab-pane label="附件" name="file" v-if="teamInfo.is_member&& teamInfo.type!=99">
          <FileData :team_id="team_id" :teamUser="teamUser" v-if="activeName == 'file'"></FileData>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- <el-row>
      <el-col :span="14">
        <el-card style="margin-right: 20px" :body-style="{height:'520px'}">
          <div style="display: flex;line-height: 40px;margin-bottom: 20px;">
            <el-avatar :src="teamInfo.logo"></el-avatar>
            <div style="margin-left: 5px;font-weight: 700;flex:1" class="shenluehao">{{ teamInfo.name }}</div>
          </div>
          <div style="display: flex;line-height: 40px;">
            <el-image :fit="'contain'" :src="teamInfo.cover" style="width:460px;height:460px;"></el-image>
            <div style="margin-left: 20px;flex:1">
              <p>创建人：{{teamInfo.create_name}}</p>
              <p>团队人数：{{teamInfo.group_num || 0}}人</p>
              <p>地域：{{teamInfo.province_str || ''}}</p>
              <p>类别：{{teamInfo.type_str || ''}}</p>
              <p>团队介绍：<span v-html="teamInfo.description"></span></p>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="10" :body-style="{height:'520px'}">
        <el-card :body-style="{height:'520px'}">
          <div style="display: flex;line-height: 40px;margin-bottom: 20px;">
            <el-avatar :src="teamCurretUser.headimg"></el-avatar>
            <div style="margin-left: 5px;font-weight: 700;flex:1" class="shenluehao">{{ teamCurretUser.nickname }}</div>
          </div>
          <div style="line-height: 40px;">
            <p>参赛次数：{{ teamCurretUser.exer_times ? teamCurretUser.exer_times : 0 }}次</p>
            <p>练习次数：{{ teamCurretUser.take_times ? teamCurretUser.take_times : 0 }}次</p>
            <p>身份：{{ teamCurretUser.identity }}</p>
          </div>
        </el-card>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import stu from "@/views/api/teaching/questOther/c/stu.vue";
import stuSituation from "@/views/api/teaching/questOther/c/stuSituation.vue";
import attendanceList from "@/views/api/teaching/questOther/attendanceList.vue";
import totalDetail from "@/views/api/teaching/questOther/totalDetail.vue";
import workList from "@/views/api/team/c/workList.vue";
import QuestList from "@/views/api/team/c/QuestList.vue";
import QuestionList from "@/views/api/team/c/QuestionList.vue";
import NoticeList from "@/views/api/team/c/NoticeList.vue";
import FileData from "@/views/api/team/c/FileData.vue";

export default {
  components: {
    stu,
    attendanceList,
    totalDetail,
    stuSituation,
    QuestList,
    QuestionList,
    NoticeList,
    FileData,
    workList
  },
  props: {
    team_id: {
      default: () => 0,
    },
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      teamInfo: {},
      teamUser: {},
      teamCurretUser: {},
      page: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      activeName: "info",
      sort:""
    };
  },
  created(){
    console.log(this.$route.query,"~~~~~~~~~")
    if(this.$route.query.activeName){
      this.course_id=Number(this.$route.query.course_id)
      this.activeName=this.$route.query.activeName
    }else{
      this.activeName='info' 
    }
    console.log(this.activeName,'this.activeName')
  },  
  methods: {
    handleClick(tab) {
      console.log(tab);
      console.log(tab.name, "namename");
      this.activeName = tab.name;
    },
    routeUpdate() {
      this.getDetail(this.team_id);
    },
    // 学生排序
    courseSortChange(column){
      // console.log(column,"column")
      const orderData=column.order == "descending" ? "desc" : column.order == "ascending" ? "asc" : null
      // console.log(orderData,"orderData")
      this.sort=column.prop+'-'+orderData
      // console.log(this.sort,"this.sort")
      this.getList();

    },
    getDetail() {
      if (
        this.team_id > 0 &&
        parseInt(this.team_id) !== parseInt(this.teamInfo.id || 0)
      ) {
        this.loading = true;
        this.$http
          .post("/api/v1_0_0.user_group/detail", { id: this.team_id })
          .then((data) => {
            if (data.data.info) {
              
              let teamInfo = data.data.info || {};
              if (
                this.userInfo.id > 0 &&
                !(
                  teamInfo.isManager ||
                  teamInfo.isManager === false ||
                  teamInfo.isManager === 0
                )
              ) {
                teamInfo.isManager =
                this.userInfo.id + "" === teamInfo.user_id + "";
              }
              this.teamInfo = { ...teamInfo };
              console.log(this.teamInfo,"this.teamInfothis.teamInfo");
              this.teamUser = { ...data.data.user };
              // console.log(this.teamUser ,"this.teamUser this.teamUser ")
              this.teamCurretUser = { ...data.data.curretUser };
              this.$nextTick(()=>{
                this.getList();
              })

            } else {
              this.$handleMessage(data.msg, "error", () => {
                this.$routerGo(-1);
              });
            }
          })
          .catch()
          .finally(() => {
            this.loading = false;
          });
      }
    },
    // 获取学生列表
    getList() {
      this.tableLoading = true;
      let type=this.teamInfo.type==99?99:2
      this.$http
        .post(
          `xapi/user.group/memberList?page=` +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&team_id=" +
            this.team_id +
            "&type="+type+"&sort=" +
            this.sort
        )
        .then((res) => {
          this.tableLoading = false;
          this.tableData = res.data.data;
          this.total = res.data.total;
        })
        .catch(({ code, msg }) => {
          console.log(code);
          console.log(msg);
        });
    },
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>